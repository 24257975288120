import {routes} from "@js/helpers/routes";
import {AjaxHelper} from "@js/helpers/ajax";
import 'jquery-ui/ui/widgets/autocomplete';

export function initAutocompleteListeners() {
    const ajax = new AjaxHelper()
    const location = routes.public.mainPage.getOrganizationsList();
    ajax.sendRequest('get', location, {}, {},(res) => {
        if (res?.success) {
            $('#institutionMainPageName').autocomplete({
                source: res.list,
                minLength: 1,
                open: function() {
                    $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
                },
                close: function() {
                    $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
                },
                focus: function() {
                    return false;
                },
                select: function(event, ui) {
                    this.value = ui.item.value;
                    return false;
                }
            }).data("ui-autocomplete")._renderItem = function(ul, item) {
                let newText = String(item.value).replace(new RegExp(this.term, "gi"),
                    "<b>$&</b>");
                return $("<li></li>")
                    .data("item.autocomplete", item)
                    .append("<div>" + newText + "</div>")
                    .appendTo(ul);
            };
        }
    });
}