import queryString from 'query-string';

export function getUrlParamsString() {
    let urlParamsString = $(window.location)
        .attr('href').split('?')[1];
    urlParamsString = decodeURIComponent(urlParamsString);
    urlParamsString = urlParamsString.replace(/\[[0-9]*\]/g, '[]');
    return urlParamsString;
}

export function reloadPage() {
    window.location.reload();
}

export function getUrlParameter(paramName) {
    const urlParamsString = getUrlParamsString();
    const result = queryString.parse(urlParamsString, {arrayFormat: 'bracket'});
    return result[paramName] ? result[paramName] : null;
}

export function replaceUrlParam(paramName, paramValue) {
    const urlParamsString = getUrlParamsString();
    return replaceQueryParam(urlParamsString, paramName, paramValue);
}

export function formQueryString(params) {
    return queryString.stringify(params, {arrayFormat: 'bracket'});
}

export function removeQueryParam(urlParamsString, paramName) {
    const parsed = queryString.parse(urlParamsString);
    delete parsed[paramName];
    return queryString.stringify(parsed);
}

export function appendQueryParam(urlParamsString, paramName, paramValue) {
    const parsed = queryString.parse(urlParamsString);
    parsed[paramName] = paramValue;
    return queryString.stringify(parsed);
}

export function replaceQueryParam(urlParamsString, paramName, paramValue) {
    const result = queryString.parse(urlParamsString, {arrayFormat: 'bracket'});
    result[paramName] = paramValue;
    return queryString.stringify(result, {arrayFormat: 'bracket'});
}

export function getCleanUrl(URL) {
    const clearURL = URL.split('?')[0];
    if (clearURL.slice(-1) !== '/') {
        return `${clearURL}/`;
    }
    return clearURL;
}

export function reloadWithoutGetParams() {
    window.location.search = '';
}
