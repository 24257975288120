import "@js/components/select2";
import {config} from "@js/config/config";
import Notifier from "@js/components/notifier";
import {routes} from "@js/helpers/routes";

export function initFiltersListeners() {
    $(document).ready(function() {
        $('button[type="button"]').click(function(event) {
            event.preventDefault();
        });
        $('#mainPageForm')
            .on('click', '.search-btn', function(event) {
                initTermInSearchString(event)
            })
            .on('keyup', function(event) {
                const codeEnter = 13;
                if(event.keyCode === codeEnter) {
                    initTermInSearchString(event);
                }
            });
    });
}

function initTermInSearchString(event)
{
    const notifier = new Notifier();
    event.preventDefault();
    let input = $('#institutionMainPageName').val();
    if (input === '') {
        const message = config.public.mainPage.searchForm.notifierErrorText;
        notifier.show(message, config.notifier.accessType.error);
    } else {
        let location = routes.public.educationSearch.educationSearch();
        location = location + '?' + 'term=' + input;
        window.location = location;
    }
}