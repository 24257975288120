import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class Notifier {
    show(title, accessType, newOptions = {}) {
        const defaultOptions = {
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        }

        const options = {
            ...defaultOptions,
            ...newOptions
        }

        const toast = Swal.mixin(options);

        toast.fire({
            icon: accessType,
            title: title
        })



    }
}

