import {getCleanUrl} from "@js/helpers/location";

const currentUrl = getCleanUrl(window.location.href);

export const routes = {
    admin: {
        institutions: {
            index: (() => {
                return '/institutions';
            }),
            saveCreate: (() => {
                return '/institutions/create';
            }),
            saveEdit: ((institutionId) => {
                return `/institutions/${institutionId}/edit`;
            }),
            edit: ((institutionId) => {
                return `/institutions/${institutionId}/edit`;
            }),
            remove: ((institutionId) => {
                return `/institutions/${institutionId}/delete`;
            })
        },
        users: {
            saveCreate: (() => {
                return '/users/create';
            }),
            saveEdit: ((userId) => {
                return `/users/${userId}/edit`;
            }),
            edit: ((userId) => {
                return `/users/${userId}/edit`;
            }),
            remove: ((userId) => {
                return `/users/${userId}/delete`;
            })
        },
        reports: {
            index: (() => {
                return '/reports';
            }),
            saveCreate: (() => {
                return '/reports/create';
            }),
            saveEdit: ((reportId) => {
                return `/reports/${reportId}/edit`;
            }),
            edit: ((reportId) => {
                return `/reports/${reportId}/edit`;
            }),
            getActivitiesList: ((reportId) => {
                return `/reports/${reportId}/getActivitiesList`;
            }),
            getProgramList: ((institutionId) => {
                return `/reports/${institutionId}/getProgramList`;
            }),
            isProgramActivity: ((activityId) => {
                return `/reports/${activityId}/isProgramActivity`;
            }),
            remove: ((reportId) => {
                return `/reports/${reportId}/delete`;
            })
        },
        programs: {
            saveCreate: ((institutionId) => {
                return `/institutions/${institutionId}/programs/create`;
            }),
            saveEdit: ((institutionId, programId) => {
                return `/institutions/${institutionId}/programs/${programId}/edit`;
            }),
            remove: ((institutionId, programId) => {
                return `/institutions/${institutionId}/programs/${programId}/delete`;
            })
        },
        agencies: {
            saveCreate: (() => {
                return '/agencies/create';
            }),
            saveEdit: ((agencyId    ) => {
                return `/agencies/${agencyId}/edit`;
            }),
            edit: ((agencyId) => {
                return `/agencies/${agencyId}/edit`;
            }),
            remove: ((agencyId) => {
                return `/agencies/${agencyId}/delete`;
            })
        },
        activities: {
            saveCreate: ((agencyId) => {
                return `/agencies/${agencyId}/activities/create`;
            }),
            saveEdit: ((agencyId, activityId) => {
                return `/agencies/${agencyId}/activities/${activityId}/edit`;
            }),
            remove: ((agencyId, activityId) => {
                return `/agencies/${agencyId}/activities/${activityId}/delete`;
            })
        },
        reportPrograms: {
            saveCreate: ((reportId) => {
                return `/reports/${reportId}/reportPrograms/create`;
            }),
            remove: ((reportId, reportProgramsId) => {
                return `/reports/${reportId}/reportPrograms/${reportProgramsId}/delete`;
            }),
            index: ((reportId) => {
                return `/reports/${reportId}/reportPrograms/`;
            }),
        },
        reportFiles: {
            saveCreate: ((reportId) => {
                return `/reports/${reportId}/reportFiles/create`;
            }),
            saveEdit: ((reportId, reportFilesId) => {
                return `/reports/${reportId}/reportFiles/${reportFilesId}/edit`;
            }),
            remove: ((reportId, reportFilesId) => {
                return `/reports/${reportId}/reportFiles/${reportFilesId}/delete`;
            }),
            index: ((reportId) => {
                return `/reports/${reportId}/reportFiles/`;
            }),
            edit: ((reportId, reportFilesId) => {
                return `/reports/${reportId}/reportFiles/${reportFilesId}/edit`;
            }),
        },
        institutionImport: {
            saveCreate: (() => {
                return `/institutions/institutionImportExcel/saveImport`;
            }),
        },
        reportsImport: {
            saveCreate: (() => {
                return `/reports/reportsImportExcel/saveImport`;
            }),
        },
    },
    public: {
        mainPage: {
            getOrganizationsList: (() => {
                return `/getOrganizationsList`;
            }),
        },
        educationSearch: {
            educationSearch: (() => {
                return `/educationSearch`;
            }),
        },
    }
}