export class AjaxHelper {
    ajaxSetup() {
        return $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }

    sendRequest(type, url, data, options, callBack) {
        this.ajaxSetup();
        const defaultOptions = {
            type,
            url,
            data,
            dataType: 'json',
            success(response, textStatus, jqXHR) {
                if (callBack) {
                    callBack.call(self, response);
                }
            },
            error(jqXHR, textStatus, errorThrown) {
                if (callBack) {
                    callBack.call(self, jqXHR.responseJSON);
                }
            },
        };
        return $.ajax({...defaultOptions, ...options});
    }
}
