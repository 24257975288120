import {initFiltersListeners} from "@js/modules/public/mainPage/index/filtersHandle";
import {initHandleInstitutionFormListeners} from "@js/modules/public/educationSearch/index/selectMenu";
import {initAutocompleteListeners} from "@js/modules/public/mainPage/index/autocomplate";

$(() => {
    initFiltersListeners();
    initHandleInstitutionFormListeners()
    initAutocompleteListeners()
});


