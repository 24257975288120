export function initHandleInstitutionFormListeners() {
    $('#educationSearchForm')
        .on('click', '#filterBtn', function () {
            const data = $('#educationSearchForm').serialize()
            window.location.search = (data);
        })
        .on('change', '.single', () => {
            const data = $('#educationSearchForm').serialize()
            window.location.search = (data);
        })

    $('#accreditationTypeForm')
        .on('change', '.single', () => {
            const data = $('#accreditationTypeForm').serialize()
            window.location.search = (data);
        })
        .on('click', '.filterBtn', function () {
            if (this.checked) {
                const data = $('#accreditationTypeForm').serialize()
                window.location.search = (data);
            }
        })

}