import {trans} from "@js/helpers/translator";

const buttonsCaption = trans('buttons');

export const config = {
    notifier: {
            accessType: {
                success : 'success',
                error : 'error',
                info : 'info',
            },
    },
    confirmModal: {
        defaultSettings: {
            title: 'Confirmation',
            message: 'Delete a record?',
            cancelButtonCaption: buttonsCaption['cancel'],
            confirmButtonCaption:  buttonsCaption['confirm'],
        }
    },
    public: {
        mainPage: {
            searchForm: {
                notifierErrorText: 'Please enter your details before searching',
            }
        }
    }
};